jQuery(document).ready(function ($) {
  var $modalOverlay = $(".modal-overlay"),
    $modalContainer = $(".modal-container"),
    $modalTrigger = $(".button-cta-trigger"),
    $modalClose = $(".modal-close");

  $modalTrigger.on("click", function () {
    $modalContainer.addClass("modal--show");
    $modalOverlay.addClass("modal--show");
  });

  $modalOverlay.on("click", function () {
    $modalContainer.removeClass("modal--show");
    $modalOverlay.removeClass("modal--show");
  });

  $modalClose.on("click", function () {
    $modalContainer.removeClass("modal--show");
    $modalOverlay.removeClass("modal--show");
  });


  /*
  function getCookie(c_name) {
    var i,
      x,
      y,
      ARRcookies = document.cookie.split(";");

    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
      x = x.replace(/^\s+|\s+$/g, "");
      if (x == c_name) {
        return unescape(y);
      }
    }
  }

  function set_cook_exit_user() {
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = "exit_user=yes; " + expires + "; path=/";
  }

  // Popup_exit
  var exitClose = document.querySelector("#exit-popup .exit_popup_close"),
    exitBtnClose = document.querySelector("#exit-popup .closeBtn");

  exitClose.addEventListener("click", () => {
    document.getElementById("exit-popup").remove();
    set_cook_exit_user();
  });

  exitBtnClose.addEventListener("click", () => {
    document.getElementById("exit-popup").remove();
    set_cook_exit_user();
  });

  var exitStatus = getCookie("exit_user");

  document.addEventListener(
    "mouseleave",
    function (e) {
      exitStatus = getCookie("exit_user");

      if (exitStatus == "undefined") {
        e.preventDefault();
      } else {
        if (e.clientY < 0 && !exitStatus) {
          document.getElementById("exit-popup").style.display = "flex";
        }
      }
    },
    false
  );

  $(document).ready(function () {
    if (exitStatus == "yes") {
      document.getElementById("exit-popup").remove();
    }
  });

  function exitP() {
    exitShow.style.display = "flex";
  }

  if ($(window).width() <= 768.5) {
    var exitShow = document.getElementById("exit-popup");
    setTimeout(exitP, 50000);
  }
*/

});
